<template>

  <q-inner-loading
    v-bind="{ dark:true, ...$attrs }"
    v-on="$listeners">

    <q-spinner size="50px" color="primary" />

  </q-inner-loading>

</template>

<script>
export default{
  name: 'BaseInnerLoading',
  inheritAttrs: false,
}
</script>

