export default {
  state : {
    priorities: [
      { value: 0, label: 'Bassa', color: 'warning' },
      { value: 1, label: 'Normale', color: 'positive' },
      { value: 2, label: 'Alta', color: 'negative' },
    ],

    status: [
      { value: 1, label: 'Creata', color: 'negative', icon: 'mdi-progress-alert' },
      { value: 2, label: 'In Lavorazione', color: 'warning', icon: 'mdi-hammer-screwdriver' },
      { value: 3, label: 'Conclusa', color: 'positive', icon : 'mdi-checkbox-marked-circle-outline' },
    ],

  },
  getters : {

    getPriority : state => ( value ) => {
      return state.priorities.find( priority => {
        return priority.value === value
      })
    },

    getPriorityLabel : ( state, getters ) => ( value ) => {
      return getters.getPriority( value ).label ?? value
    },

    getPriorityColor : ( state, getters ) => ( value ) => {
      return getters.getPriority( value ).color ?? value
    },

    getStatus : state => ( value ) => {
      return state.status.find( status => {
        return status.value === value
      })
    },

    getStatusLabel : ( state, getters ) => ( value ) => {
      return getters.getStatus( value ).label ?? value
    },

    getStatusColor : ( state, getters ) => ( value ) => {
      return getters.getStatus( value ).color ?? value
    },

    getStatusIcon : ( state, getters ) => ( value ) => {
      return getters.getStatus( value ).icon ?? value
    },

  },
}
