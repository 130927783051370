<template>
  <q-btn
    v-bind="{ color: 'secondary', ...$attrs }"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
  </q-btn>
</template>

<script>
export default {
  name: 'BaseBtn',
  inheritAttrs: false,

}
</script>
