import Api from '../../apis/brands.js'

export default {
  state : {
    collection: [],
    loaded: false,
  },
  getters : {
    getById : state => id => {
      if( !state.loaded ) return []
      return state.collection.find( item => {
        return item.id == id
      })
    },
    filter : state => query => {
      if( !state.loaded ) return []

      if( !query.length ) return state.collection

      return state.collection.filter( item =>item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0 )
    },
  },
  actions : {
    load( { commit, state } ){
      if( !state.loaded ){
        return Api.index().then( response => {
          commit( 'SET_COLLECTION', response.data )
        })
      }
    },
    store( { dispatch, state }, data ){
      return Api.store( data  ).then( response => {
        state.loaded = false
        dispatch( 'load' )
        return response.data
      })
    },
  },
  mutations : {
    SET_COLLECTION( state, data ){
      state.collection = data
      state.loaded = true
    },
  },
}
