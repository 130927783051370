import { parseBool } from '../../hooks/helpers'
import { store } from '../../store'

export default [
  {
    path: '/users',
    name: 'users',
    component: () =>
      import(/* webpackChunkName: "users" */ '../../views/Users.vue'),
    meta: {
      breadcrumb: {
        label: 'Lista utenti',
      },
      casts: {
        unactive: parseBool,
      },
      auth() {
        return store.getters['auth/userCan']('user_manage')
      },
    },
    children: [
      {
        name: 'userCreate',
        path: 'create',
        meta: {
          inject: {
            id: 'create',
          },
          breadcrumb: {
            label: 'Nuovo utente',
          },
          auth() {
            return store.getters['auth/userCan']('user_manage')
          },
        },
      },
      {
        name: 'userShow',
        path: ':id',
        meta: {
          casts: {
            id: parseInt,
          },
          breadcrumb: {
            label: 'Dettaglio utente',

            dynamicPath: true,
            path: (route) => `/users/${route.params.id}`,
          },
          auth() {
            return store.getters['auth/userCan']('user_manage')
          },
        },
        children: [
          {
            name: 'userEdit',
            path: 'edit',
            meta: {
              inject: {
                action: 'edit',
              },
              breadcrumb: {
                label: 'Modifica utente',
              },
              auth() {
                return store.getters['auth/userCan']('user_manage')
              },
            },
          },
          {
            name: 'userDestroy',
            path: 'destroy',
            meta: {
              inject: {
                action: 'destroy',
              },
              auth() {
                return store.getters['auth/userCan']('user_manage')
              },
            },
          },
        ],
      },
    ],
  },
]
