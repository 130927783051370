import { onMounted, onUnmounted } from '@vue/composition-api'

export default function useWindowEvent( eventName, handle, options = {} ){
  onMounted( () => {
    window.addEventListener( eventName, handle )

    if( options.immediate ){
      handle()
    }
  })

  onUnmounted( () => {
    window.removeEventListener( eventName, handle )
  })
}
