<template>
  <div v-on="$listeners">

    <!-- header slot -->
    <slot name="header">
      <h5
        class="text-h6 text-black q-mt-lg q-mb-md"
        v-if="header"
      >
        {{ header }}
      </h5>
    </slot>
    <q-card
      class="card"
      :class="{ 'q-mb-lg' : !nomargin }"
      v-bind="{...$attrs }"
    >

      <q-card-section
        class="q-pb-sm flex"
        v-if="toolbar"
      >

        <div class="full-width">
          <!-- title slot -->
          <slot name="title">
            <h1
              class="text-h6 text-weight-bold q-pa-none q-ma-none flex"
              v-if="title"
            >
              {{ title }}

              <slot name="header-action" />

            </h1>
          </slot>

          <!-- subtitle slot -->
          <slot name="subtitle">
            <p
              class="text-subtitle2 text-grey-1 q-mb-none q-ma-none"
              v-if="subtitle"
            >
              {{ subtitle }}
            </p>
          </slot>
        </div>

        <!-- action slot -->
        <div class="q-ml-auto">
          <slot name="actions" />
        </div>

      </q-card-section>

      <q-card-section
        :class="{ 'q-pt-none' : toolbar, 'q-pa-none' : multiSection }"
      >
        <!-- page content -->
        <slot/>
        <!-- page content -->
      </q-card-section>

      <!-- footer actions --->
      <template v-if="!!$slots.footer">
        <q-separator />
        <q-card-actions class="q-pa-md full-width">
          <slot name="footer" />
        </q-card-actions>
      </template>

    </q-card>

  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
export default{
  name: 'BaseCard',

  inheritAttrs : false,

  props: {
    header: {
      type: String,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    nomargin: {
      type: Boolean,
    },
    multiSection: {
      type: Boolean,
    }
  },
  setup(props, context){

    const toolbar = computed(() => {
      return props.title || props.subtitle || context.slots.title || context.slots.subtitle || context.slots.actions
    })

    return {
      toolbar
    }
  }
}
</script>
