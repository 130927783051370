import isArray from 'lodash/isArray'
import map from 'lodash/map'
import { computed } from '@vue/composition-api'
import { store } from '../store/index.js'

function parseArguments( args ){
  let ns = args.length > 1 ? args.shift() : ''
  let values = args.pop()
  let alias = !isArray( values )

  return {
    alias,
    ns,
    values,
  }
}

function setName( v, ns, alias ){
  return ( ns ? `${ns}/` : '' ) + ( alias ? v[1] : v[0] )
}

export function useActions( ...args ){
  let { ns, values, alias } = parseArguments( args )

  return Object.fromEntries(
    map( values, ( ...v ) => [
      v[0],
      input => store.dispatch( setName( v, ns, alias ), input )
    ])
  )
}

export function useGetters( ...args ){
  let { ns, values, alias } = parseArguments( args )

  return Object.fromEntries(
    map( values, ( ...v ) => {
      let name = setName( v, ns, alias )
      let value = store.getters[ name ]

      return [
        v[0],
        input => typeof value == 'function' ? computed( () => value( input ) ) : computed( () => value )
      ]
    })
  )
}

export function useState( ...args ){
  let { ns, values, alias } = parseArguments( args )

  return Object.fromEntries(
    map( values, ( ...v ) => [
      v[0],
      computed( () => ns ? store.state[ ns ][ alias ? v[1] : v[0] ] : store.state[ alias ? v[1] : v[0] ] )
    ])
  )
}

export function useStore(){
  return store
}
