export default [
  {
    path: '/email-messages',
    name: 'emailMessages',
    component: () =>
      import(
        /* webpackChunkName: "emailMessages" */ '../../views/EmailMessages.vue'
      ),
    meta: {
      breadcrumb: {
        label: 'Lista messaggi',
      },
    },
    children: [
      {
        name: 'emailMessageCreate',
        path: 'create',
        meta: {
          inject: {
            id: 'create',
          },
          breadcrumb: {
            label: 'Nuovo messaggio',
          },
        },
      },
      {
        name: 'emailMessageShow',
        path: ':id',
        meta: {
          casts: {
            id: parseInt,
          },
          breadcrumb: {
            label: 'Dettaglio messaggio',

            dynamicPath: true,
            path: (route) => `/email-messages/${route.params.id}`,
          },
        },
        children: [
          {
            name: 'emailMessageEdit',
            path: 'edit',
            meta: {
              inject: {
                action: 'edit',
              },
              breadcrumb: {
                label: 'Modifica messaggio',
              },
            },
          },
          {
            name: 'emailMessageDestroy',
            path: 'destroy',
            meta: {
              inject: {
                action: 'destroy',
              },
            },
          },
        ],
      },
    ],
  },
]
