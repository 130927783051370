import camelCase from 'lodash/camelCase'
import Vue from 'vue'
import Vuex from 'vuex'
import { Plugins } from '@capacitor/core'

const { Device } = Plugins

Vue.use( Vuex )

//dynamics load modules
const requireModule = require.context( './modules/', false, /\.js$/ )
const modules = {}
requireModule.keys().forEach( fileName => {
  const moduleName = camelCase( fileName.replace(/(\.\/|\.js)/g, '') )
  modules[moduleName] = {
    namespaced: true,
    ...requireModule( fileName ).default,
  }
})

//create store
const store = new Vuex.Store({
  state : {
    ready: false,
    uuid: '',
    dirty: false,
    ajaxPendings : 0,
    ajaxLoading : false,
  },
  getters : {},
  actions : {
    setReady( { commit } ){
      commit( 'READY' )
    },
    addAjaxPending( { commit } ){
      commit( 'PUSH_AJAX_PENDING' )
    },
    removeAjaxPending( { commit } ){
      commit( 'PULL_AJAX_PENDING' )
    },
    setDirty( { commit }, value ){
      commit( 'SET_DIRTY', value )
    },
    setUuid( { commit } ){
      Device.getInfo().then(info => {
        commit( 'SET_UUID', info.uuid )
      })
    },
  },
  mutations : {
    READY( state ){
      state.ready = true
    },
    PUSH_AJAX_PENDING( state ){
      state.ajaxPendings++
      state.ajaxLoading = true
    },
    PULL_AJAX_PENDING( state ){
      state.ajaxPendings--
      state.ajaxLoading = state.ajaxPendings > 0
    },
    SET_DIRTY( state, value ){
      state.dirty = value
    },
    SET_UUID( state, value ){
      state.uuid = value
    },
  },
  modules,
})

export { store, modules }
