import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'

Vue.use( VueCompositionApi )

//vuex
import { store } from './store/index.js'

//vue router
import { router } from './config/router.js'

//scss
import './scss/main.scss'

//quasar
import './config/quasar.js'

//ionic capacitor
import { defineCustomElements } from '@ionic/pwa-elements/loader'

//init
const app =  new Vue({
    render: h => h( require( './views/App.vue' ).default ),
    store,
    router,
}).$mount('#app')

//load base global components
const requireComponents = require.context( './components/base/', false, /[\w]+\.vue$/ )

requireComponents.keys().forEach( fileName => {
    Vue.component( fileName.replace(/^\.\//, '').replace(/\.\w+$/, ''), requireComponents( fileName ).default );
})

//define capacitor
defineCustomElements(window)

export { app }
