import Vue from 'vue'
import lang from 'quasar/lang/it.js'
import Cookies from 'quasar/src/plugins/Cookies.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';import LoadingBar from 'quasar/src/plugins/LoadingBar.js';import Notify from 'quasar/src/plugins/Notify.js';import Quasar from 'quasar/src/vue-plugin.js';

Vue.use( Quasar, {
  plugins: {
    Cookies,
    Dialog,
    Loading,
    LoadingBar,
    Notify,
  },
  config: {
    loading: {
    },
    loadingBar: {
      color: 'grey-2',
      size: '4px',
      position: 'top',
      skipHijack: true,
    },
    notify: {
      group: false,
      progress: true,
    },
  },
  lang: lang
})
