
//dynamics load routes
const requireRoute = require.context( './modules/', false, /\.js$/ )
let routes = []
requireRoute.keys().forEach( fileName => {
  routes = routes.concat( requireRoute( fileName ).default )
})

export default [
  {
    path : '/',
    redirect : '/dashboard'
  }, {
    path : '/dashboard',
    name : 'home',
    component : () => import( /* webpackChunkName: "dashboard" */ '../views/Dashboard.vue' ),
  },
].concat( routes ).concat([
  {
    path : '/:catchAll(.*)',
    name : '404',
    component : () => import( /* webpackChunkName: "e404" */ '../views/errors/404.vue' ),
    meta : {
      guest: true,
    },
  },
])
