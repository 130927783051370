import axios from 'axios'
import { store } from '../store/index.js'
import { router } from  '../config/router.js'

//init object
const Api = axios.create({
  baseURL : ( process.env.NODE_ENV == 'development' ? process.env.VUE_APP_API_BASE_PATH_DEV : process.env.VUE_APP_API_BASE_PATH ) || '/api/',
  headers : {
    'X-Requested-With' : 'XMLHttpRequest',
  },
  withCredentials: true,
})

//get from localStorage
let token = localStorage.getItem( 'token' )
if( token ){
  Api.defaults.headers.common[ 'Authorization' ] = `Bearer ${token}`
}

//increase pending counter
Api.interceptors.request.use( config => {
  store.dispatch( 'addAjaxPending' )
  return config
}, error => {
  store.dispatch( 'removeAjaxPending' )
  return Promise.reject( error )
})

//reponse catcher
Api.interceptors.response.use( response => {
  store.dispatch( 'removeAjaxPending' )
  return response.data || response
}, error => {
  store.dispatch( 'removeAjaxPending' )

  switch( error.response.status ){
    //authentication required redirect to login
    case 401:
      if( store.getters[ 'auth/isAuthenticated'] ){
        store.dispatch( 'auth/tokenExpired' ).then( () => {
          router.push( { name : 'login' } )
        })
      }
      else {
        router.push( { name : 'login' } )
      }
      break
    default:
      break
  }

  return Promise.reject( error.response.data )
})

export default Api
