export default [
  {
    path : '/maintenances',
    name : 'maintenances',
    component : () => import( /* webpackChunkName: "maintenances" */ '../../views/Maintenances.vue' ),
    meta: {
      breadcrumb: {
        label : 'Lista manutenzioni',
      },
      casts: {
        kind : parseInt,
      },
    },
    children: [
      {
        name: 'maintenanceCreate',
        path: 'create',
        meta: {
          inject: {
            id : 'create',
          },
          breadcrumb: {
            label:'Nuova manutenzione',
          },
        },
      },
      {
        name: 'maintenanceShow',
        path: ':id',
        meta: {
          casts: {
            id : parseInt,
          },
          breadcrumb: {
            label: 'Dettaglio manutenzione',

            dynamicPath: true,
            path: route => `/maintenances/${route.params.id}`,

          },
        },
        children: [
          {
            name: 'maintenanceOperation',
            path: 'operations/:operation/:operation_action?',
            meta: {
              inject : {
                action: 'operations',
              },
              breadcrumb: {
                dynamicLabel: true,
                label: route => route.params.operation_action ? 'Modifica operazione' : ( route.params.operation == 'create' ? 'Nuova operazione' : 'Dettagli operazione' ),
              },
            },
          },
          {
            name: 'maintenanceEdit',
            path: 'edit',
            meta: {
              inject : {
                action: 'edit',
              },
              breadcrumb: {
                label: 'Modifica manutenzione',
              },
            },
          },
          {
            name: 'maintenanceDestroy',
            path: 'destroy',
            meta: {
              inject: {
                action: 'destroy',
              },
            },
          },
        ],
      },
    ],
  },
]
