import Api from '../../apis/operations.js'

export default {
  state : {
    types: [],
    typesLoaded: false,
  },
  getters : {
    getTypeById : state => id => {
      if( !state.typesLoaded ) return []
      return state.types.find( item => {
        return item.id == id
      })
    },
    filterTypes : state => query => {
      if( !state.typesLoaded ) return []

      if( !query.length ) return state.types

      return state.types.filter( item =>item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0 )
    },
  },
  actions : {
    loadTypes( { commit, state } ){
      if( !state.typesLoaded ){
        return Api.typeIndex().then( response => {
          commit( 'SET_TYPES', response.data )
        })
      }
    },
    storeType( { dispatch, state }, data ){
      return Api.typeStore( data  ).then( response => {
        state.typesLoaded = false
        dispatch( 'loadTypes' )
        return response.data
      })
    },
  },
  mutations : {
    SET_TYPES( state, data ){
      state.types = data
      state.typesLoaded = true
    },
  },
}
