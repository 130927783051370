<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 112 110"
    :class="{ 'logo-white': !colored, 'logo-color': colored }"
  >
    <g>
      <g>
        <path
          d="M83.18,62.28C96,59.16,105.52,47.6,105.52,33.82c0-13.28-8.83-24.49-20.94-28.09
            c-0.27-0.08-0.86-0.14-1.2,0.6c-0.34,0.73-4.5,9.65-4.83,10.35c-0.33,0.7,0.26,1.21,0.64,1.29c7.37,1.51,12.93,8.04,12.93,15.85
            c0,8.92-7.26,16.18-16.18,16.18h-5.62v0l-5.3,0c-0.95,0-1.4,0.7-1,1.56l12.56,27.07c0.4,0.86,1.5,1.56,2.45,1.56h10.8
            c0.95,0,1.4-0.7,0.99-1.56L83.18,62.28z"
        />
        <path
          d="M27.68,54.16c0.4,0.86,1.05,0.86,1.46,0l5.62-11.99c0.4-0.86,0.41-2.26,0.01-3.12L19.74,6.64
            c-0.4-0.86-1.5-1.56-2.45-1.56L6.72,5.1c-0.95,0-1.4,0.71-1,1.57L27.68,54.16z"
        />

        <path
          class="line"
          d="M40.77,78.78l33.58-72.2c0.4-0.86-0.05-1.56-1-1.57L62.77,5c-0.95,0-2.05,0.7-2.45,1.56l-26.6,57.07
            c-0.4,0.86-0.4,2.27,0,3.13l5.61,12.03C39.72,79.64,40.37,79.64,40.77,78.78"
        />

        <path
          d="M15.51,101.47c-0.64,0.26-1.37,0.39-2.17,0.39c-1,0-1.87-0.22-2.62-0.67c-0.75-0.45-1.32-1.07-1.73-1.87
            c-0.4-0.8-0.6-1.73-0.6-2.79c0-0.97,0.22-1.86,0.65-2.66c0.43-0.8,1.02-1.43,1.75-1.89c0.73-0.46,1.52-0.69,2.35-0.69
            c1.24,0,2.45,0.45,3.63,1.34l1.28-3.07c-0.64-0.4-1.39-0.74-2.24-1.01c-0.85-0.27-1.78-0.4-2.78-0.4c-1.58,0-2.98,0.36-4.19,1.09
            c-1.21,0.73-2.15,1.74-2.82,3.04C5.34,93.57,5,95.05,5,96.71c0,1.51,0.34,2.89,1.01,4.16c0.67,1.26,1.64,2.27,2.91,3.01
            c1.27,0.75,2.76,1.12,4.48,1.12c1.08,0,2.07-0.17,2.98-0.52c0.91-0.34,1.58-0.69,1.99-1.03l-1.34-2.91
            C16.66,100.9,16.15,101.21,15.51,101.47"
        />
      </g>

      <path
        d="M28.3,94.85l1.59,4.01h-3.25L28.3,94.85z M28.39,87.68l-7.44,17.14h3.2l1.34-3.23h5.47l1.28,3.23h3.76
        l-7.44-17.14H28.39z"
      />
      <polygon
        points="56.53,87.66 47.97,99.51 39.62,87.66 39.59,87.66 39.59,104.82 42.73,104.82 42.73,97.45 47.91,104.6
        47.95,104.6 53.33,96.96 53.33,104.82 56.58,104.82 56.58,87.66 	"
      />
      <g>
        <path
          d="M69.86,95.05c-0.16,0.37-0.43,0.7-0.81,0.97c-0.37,0.28-0.87,0.41-1.48,0.41h-2.31v-4.97h2.33
            c0.99,0,1.65,0.27,1.99,0.82c0.34,0.55,0.52,1.11,0.52,1.69C70.11,94.32,70.03,94.68,69.86,95.05 M67.02,88.33h-5v16.49h3.25V99.6
            h2.33c1.36,0,2.47-0.28,3.33-0.83c0.86-0.55,1.48-1.26,1.86-2.12c0.38-0.86,0.57-1.78,0.57-2.77c0-1.72-0.55-3.07-1.66-4.07
            C70.59,88.82,69.03,88.33,67.02,88.33"
        />
      </g>

      <polygon
        points="77.46,88.33 77.46,104.82 89.02,104.82 89.02,101.68 80.71,101.68 80.71,98.12 87.79,98.12
        87.79,94.98 80.71,94.98 80.71,91.46 88.71,91.46 88.71,88.33 	"
      />
      <g>
        <path
          d="M101.05,95.67c-0.4,0.52-1.05,0.77-1.93,0.77h-2.31v-4.97h2.33c0.99,0,1.65,0.27,1.99,0.82
            c0.34,0.55,0.52,1.12,0.52,1.71C101.66,94.59,101.46,95.15,101.05,95.67 M102.42,98.84c0.88-0.51,1.53-1.21,1.96-2.1
            c0.43-0.89,0.64-1.84,0.64-2.86c0-1.72-0.56-3.07-1.69-4.07c-1.13-0.99-2.71-1.49-4.76-1.49h-5v16.49h3.25v-5.24h2.17l3.47,5.24
            h3.79L102.42,98.84z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logo',

  props: {
    colored: {
      type: Boolean,
    },
  },
}
</script>

<style>
.logo-white {
  fill: #ffffff;
}

.logo-color {
  fill: #003057;
}

.logo-color .line {
  fill: #3eb1c8;
}
</style>
