<template>

  <BaseInput
    mask="##/##/#### ##:##"
    :value="inputDate"
    v-bind="$attrs"
    @input="input"
  >
    <template v-slot:append>
      <q-icon
        name="event"
        class="cursor-pointer">
        <q-popup-proxy
          transition-show="scale"
          transition-hide="scale">
          <q-date v-model="dateModel" mask="YYYY-MM-DD HH:mm">
            <div class="row items-center justify-end">
              <q-btn
                v-close-popup
                label="Chiudi"
                color="primary"
                flat />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>

      <q-icon
        name="access_time"
        class="cursor-pointer">
        <q-popup-proxy
          transition-show="scale"
          transition-hide="scale">
          <q-time v-model="dateModel" mask="YYYY-MM-DD HH:mm" format24h>
            <div class="row items-center justify-end">
              <q-btn
                v-close-popup
                label="Chiudi"
                color="primary"
                flat />
            </div>
          </q-time>
        </q-popup-proxy>
      </q-icon>
    </template>

  </BaseInput>

</template>

<script>
import { date } from 'quasar'
import { computed } from '@vue/composition-api'

export default {
  name: 'BaseDateTimePicker',
  inheritAttrs: false,

  props: {
    value : {
      type: String,
    },
  },

  setup(props, { emit }){
    const inputDate = computed( () => props.value ? date.formatDate( new Date( props.value ), 'DD/MM/YYYY HH:mm' ) : '' )

    const dateModel = computed({
      get(){
        return props.value || ''
      },
      set( value ){
        emit( 'input', value )
      }
    })

    function input( value )
    {
      if( /^-?[0-3]\d\/[0-1]\d\/[\d]{4} [0-2]\d:[0-9]\d$/.test( value ) ) {
        emit( 'input', date.formatDate( date.extractDate( value, 'DD/MM/YYYY HH:mm' ), 'YYYY-MM-DD HH:mm' ) )
        return
      }
      emit( 'input', '' )
    }

    return {
      dateModel,
      input,
      inputDate,
    }
  },

}
</script>
