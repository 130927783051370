<template>
  <q-banner
    inline-actions
    rounded
    v-bind="$attrs"
    v-on="$listeners"
    :class="`bg-${color} text-${textColor}`"
  >
    <template v-slot:avatar v-if="!!icon">
      <q-icon :name="icon" />
    </template>

    {{ message }}

    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />

  </q-banner>
</template>

<script>
export default{
  name: 'BaseBanner',
  inheritAttrs: false,

  props: {
    color: {
      type: String,
      default: 'primary',
    },
    icon: String,
    message: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: 'white',
    },
  },

}
</script>

