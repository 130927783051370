<template>
  <q-select
    ref="select"
    :error="error"
    :error-message="errorMessage"
    v-bind="{ filled:true, 'stack-label': true, 'no-error-icon': true, color:'secondary', ...$attrs }"
    v-on="$listeners"
  >

    <template v-for="(_, name) in $slots">
      <slot v-if="name!='option'" :name="name" :slot="name" />
    </template>

    <template v-slot:option="scope" v-if="!native">
      <slot name="option" :item="scope"></slot>
    </template>

  </q-select>
</template>

<script>
export default {
  name: 'BaseSelect',
  inheritAttrs: false,

  props : {
    errorName : {
      type: String,
    },

    errors : {
      type:Object,
      default: () => {
        return null
      },
    },

    native: Boolean,
  },

  computed: {
    error(){
      return this.errors != null && this.errors.hasError( this.errorName )
    },
    errorMessage(){
      return this.errors != null ? this.errors.getError( this.errorName ) : ''
    },
  },

}
</script>
