<template>
  <q-checkbox
    :color="color"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
  </q-checkbox>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  inheritAttrs: false,

  props : {
    color: {
      type: String,
      default: 'secondary'
    }
  }

}
</script>
