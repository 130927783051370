export default [
  {
    path : '/vehicles',
    name : 'vehicles',
    component : () => import( /* webpackChunkName: "vehicles" */ '../../views/Vehicles.vue' ),
    meta: {
      breadcrumb: {
        label : 'Lista veicoli',
      },
    },
    children: [
      {
        name: 'vehicleCreate',
        path: 'create',
        meta: {
          inject: {
            id : 'create',
          },
          breadcrumb: {
            label:'Nuovo veicolo',
          },
        },
      },
      {
        name: 'vehicleShow',
        path: ':id',
        meta: {
          casts: {
            id : parseInt,
          },
          breadcrumb: {
            label: 'Dettaglio veicolo',

            dynamicPath: true,
            path: route => `/vehicles/${route.params.id}`,

          },
        },
        children: [
          {
            name: 'vehicleEdit',
            path: 'edit',
            meta: {
              inject: {
                action: 'edit',
              },
              breadcrumb: {
                label: 'Modifica veicolo',
              },
            },
          },
          {
            name: 'vehicleDestroy',
            path: 'destroy',
            meta: {
              inject: {
                action: 'destroy',
              },
            },
          },
        ],
      },
    ],
  },
]
