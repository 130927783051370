import forEach from 'lodash/forEach'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Dialog from 'quasar/src/plugins/Dialog.js';
import { store } from '../store/index.js'
import { watch } from '@vue/composition-api'
import LoadingBar from 'quasar/src/plugins/LoadingBar.js';

Vue.use( VueRouter )

//routes
import routes from '../routes/index.js'

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach( ( route, from, next ) => {
  //prevent on dirty
  if( store.state.dirty ){
    Dialog.create({
      title: 'Attenzione',
      message: 'Le modifiche non sono state salvare, continuare?',
      persistent: true,
      ok: {
        flat: true,
        label: 'Continua',
      },
      cancel: {
        flat: true,
        color: 'negative'
      },
    }).onOk(() => {
      store.dispatch( 'setDirty', false )
      process()
    })
    return;
  }

  //fist access
  if( !store.state.ready ){
    //wait for ready
    watch( () => store.state.ready, ready => {
      if( ready ){
        process()
      }
    })
    //load
    if( store.getters[ 'auth/isAuthenticated' ] ){
      store.dispatch( 'auth/loadUserData' ).then( () => {
        store.dispatch( 'setReady' )
      })
    }
    else {
      store.dispatch( 'setReady' )
    }
  }
  else {
    process()
  }

  /**
   * Process request after initialize
   */
  function process()
  {
    //progress start
    if( !store.state.ajaxLoading ){
      LoadingBar.start()
    }

    //casting query/params by function
    if( route.meta.casts !== undefined ){
      forEach( route.meta.casts, ( method, field ) => {
        //query
        if( route.query[ field ] != undefined && route.query[ field ] != null ){
          route.query[ field ] = method( route.query[ field ] );
        }
        //params
        else if( route.params[ field ] != undefined && route.params[ field ] != null ){
          route.params[ field ] = method( route.params[ field ] );
        }
      });
    }

    //inject params
    if( route.meta.inject !== undefined ){
      forEach( route.meta.inject, ( value, field ) => {
        route.params[ field ] = value
      })
    }

    //public pages
    if( route.meta.guest ) {
      next()
      return
    }

    const isAuthenticated = store.getters[ 'auth/isAuthenticated' ]

    //guest only, without user auth
    if( route.meta.guestOnly ) {
      next( isAuthenticated ? { name : 'home' } : true )
      return
    }

    //not logged to login
    next( !isAuthenticated ? { name : 'login' } : true )
    if( !isAuthenticated ){
      LoadingBar.stop()
    }

    //here check authorizations
    if( route.meta.auth ){
      const authorized = typeof route.meta.auth == 'function' ? route.meta.auth( route ) : route.meta.auth

      //non autorizzato
      if( !authorized ){
        next( { name: '403' } )
        return
      }

    }

  }

})

router.afterEach( () => {
  if( !store.state.ajaxLoading ){
    LoadingBar.stop()
  }
})

export { router }

