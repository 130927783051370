import Api from './api.js'
import fileDownload from 'js-file-download'
import useCrud from './crud.js'

const END_POINT = 'maintenances'

export default {
  ...useCrud(END_POINT),

  download(id, spares = false) {
    return Api.get(`${END_POINT}/download${spares ? '-spares' : ''}/${id}`, {
      responseType: 'blob',
    }).then((response) => {
      fileDownload(
        response,
        `Scheda_${spares ? 'ricambi' : 'officina'}_${id}.pdf`
      )
      return response
    })
  },

  downloadPendings(params = {}) {
    return Api.get(`${END_POINT}/download-pendings`, {
      params,
      responseType: 'blob',
    }).then((response) => {
      fileDownload(response, `Schede_attive.pdf`)
      return response
    })
  },

  status() {
    return Api.get(`${END_POINT}/status-index`)
  },

  setStatus(id, status) {
    return Api.patch(`${END_POINT}/${id}/status`, { status })
  },
}
