<template>
  <q-drawer
    id="sidebar"
    side="left"
    :mini-to-overlay="isMini"
    :value="isOpen"
    @input="toggleOpen"
    :show-if-above="isOpen"
    :width="260"
    :breakpoint="500"
    :mini="isMini && mini"
    @mouseover="hideMini"
    @mouseout="showMini"
  >
    <q-btn
      dense
      round
      float
      unelevated
      color="pink"
      size="sm"
      icon="mdi-menu-open"
      class="absolute"
      :style="{ zIndex: 5, top: '21px', right: '10px' }"
      :class="{ 'rotate-180': isMini, hidden: mini && (!isOpen || isMini) }"
      @click="toggleMini"
    >
      <q-tooltip>{{ isMini ? 'Mantieni aperto' : 'Minimizza' }}</q-tooltip>
    </q-btn>

    <div id="sidebar-header" ref="header">
      <div class="bg-black text-center">
        <Logo style="width: 45px" class="q-mx-xs q-my-sm" />
      </div>

      <q-expansion-item
        expand-icon="mdi-chevron-down"
        @after-hide="drawerFit"
        @after-show="drawerFit"
      >
        <template #header>
          <q-item-section class="q-py-sm" avatar>
            <q-avatar color="pink" text-color="white">
              {{ userInitial }}
            </q-avatar>
          </q-item-section>
          <q-item-section>{{ user.name }}</q-item-section>
        </template>

        <q-card class="bg-secondary" style="border-bottom: 4px solid #e91e63">
          <q-item clickable v-ripple :to="{ name: 'profile' }">
            <q-item-section side>
              <q-icon name="mdi-account-circle-outline" color="grey-1" />
            </q-item-section>
            <q-item-section> Profilo </q-item-section>
          </q-item>
        </q-card>
      </q-expansion-item>
    </div>

    <q-scroll-area :style="{ height: `${scrollerHeight}px` }">
      <q-list id="sidebar-menu">
        <template v-for="(item, index) in menuItems">
          <q-item
            clickable
            v-if="itemVisible(item)"
            v-ripple
            :key="`menu_${index}`"
            :to="item.to || { name: item.route }"
          >
            <q-item-section avatar>
              <q-icon :name="`mdi-${item.icon}`" />
            </q-item-section>
            <q-item-section>
              {{ item.label }}
            </q-item-section>
          </q-item>
        </template>
      </q-list>
    </q-scroll-area>
  </q-drawer>
</template>

<script>
import Logo from '../components/Logo.vue'
import useAuth from '../hooks/auth.js'
import useWindowEvent from '../hooks/windowEvent.js'
import { dom } from 'quasar'
import { computed, ref } from '@vue/composition-api'
import { menuItems } from '../config/menu.js'
import { useActions, useGetters, useState } from '../hooks/store.js'

export default {
  components: {
    Logo,
  },
  setup(_, { refs }) {
    const scrollerHeight = ref(0)
    const { height } = dom
    const { user, userInitial } = useAuth()
    const { userCan } = useGetters('auth', ['userCan'])
    const { isOpen, isMini } = useState('drawer', {
      open: 'isOpen',
      mini: 'isMini',
    })
    const { toggleMini, toggleOpen } = useActions('drawer', [
      'toggleMini',
      'toggleOpen',
    ])

    const logo = computed(
      () => `../assets/logo${isMini.value ? '_mini' : ''}.png`
    )

    /** Mini Menu Hover Actions **/
    const mini = ref(isMini.value)

    function showMini(event) {
      let target = event.relatedTarget || event.target
      while (target != null) {
        target = target.parentNode
        if (target && target.id == 'sidebar') {
          return
        }
      }
      mini.value = true
    }

    function hideMini() {
      mini.value = false
    }

    function drawerFit() {
      scrollerHeight.value = window.innerHeight - height(refs.header)
    }

    function itemVisible(item) {
      if (item.permission === undefined) return true

      return userCan(item.permission).value
    }

    useWindowEvent(
      'resize',
      () => {
        drawerFit()

        if (window.innerWidth < 500 && isMini.value) {
          hideMini()
        }
      },
      { immediate: true }
    )

    return {
      hideMini,
      isOpen,
      isMini,
      itemVisible,
      drawerFit,
      menuItems,
      logo,
      mini,
      showMini,
      toggleMini,
      toggleOpen,
      scrollerHeight,
      user,
      userInitial,
    }
  },
}
</script>
