<template>
  <q-input
    :error="error"
    :error-message="errorMessage"
    v-bind="{ filled:true, 'stack-label': true, 'no-error-icon': true, color:'secondary', ...$attrs }"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
  </q-input>
</template>

<script>
export default {
  name: 'BaseInput',
  inheritAttrs: false,

  props : {
    errorName : {
      type: String,
    },

    errors : {
      type:Object,
      default: () => {
        return null
      },
    },
  },

  computed: {
    error(){
      return this.errors != null && this.errors.hasError( this.errorName )
    },
    errorMessage(){
      return this.errors != null ? this.errors.getError( this.errorName ) : ''
    },
  },

}
</script>
