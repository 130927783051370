import Api from './api.js'
import { store } from '../store/index.js'

const END_POINT = 'user'

export default {
  login( email, password, withToken = false )
  {
    return Api.post( `login`, {
      email,
      password,
     'with-token' : withToken,
      device : store.state.uuid,
    })
  },

  passwordRecovery( email )
  {
    return Api.post( `forgot-password`, { email } )
  },

  passwordReset( email, token, password, password_confirmation )
  {
    return Api.post( `reset-password`, { email, token, password, password_confirmation } )
  },

  passwordUpdate( current_password, password, password_confirmation )
  {
    return Api.put( `${END_POINT}/password`, { current_password, password, password_confirmation } )
  },

  logout()
  {
    return Api.post( `logout` )
  },

  user()
  {
    return Api.get( END_POINT )
  },

}
