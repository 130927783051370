<template>
  <q-breadcrumbs v-bind="$attrs" v-on="$listeners">
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />

    <template v-for="crumb in breadcrumb">
      <q-breadcrumbs-el
        v-if="visible(crumb)"
        :label="label(crumb)"
        :to="path(crumb)"
        :key="`bc-${crumb.name}`"
      />
    </template>
  </q-breadcrumbs>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  name: 'BredCrumb',

  props: {
    breadcrumb: Array,
  },

  setup(_, ctx) {
    const route = computed(() => ctx.root.$route)

    function visible(crumb) {
      return crumb.meta.breadcrumb !== undefined
    }

    function label(crumb) {
      return crumb.meta.breadcrumb.dynamicLabel
        ? crumb.meta.breadcrumb.label(route.value)
        : crumb.meta.breadcrumb.label
    }

    function path(crumb) {
      return crumb.meta.breadcrumb.dynamicPath
        ? crumb.meta.breadcrumb.path(route.value)
        : crumb.path
    }

    return {
      label,
      path,
      visible,
    }
  },
}
</script>
