import pickBy from 'lodash/pickBy'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'

/**
 * Format money
 *
 * @param  number value
 * @param  number precision
 * @return string
 */
export function formatMoney( value, precision=2 )
{
  if( value != null && value != 0 ){
    let val = (value/1).toFixed(precision).replace('.', ',')
    return '€' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }
  else {
    return '-'
  }
}

/**
 * Remove null or empty values form object
 *
 * @param  object value
 * @return object
 */
export function objectClear( value = {} )
{
  return pickBy( value, value => {
    //always keep value === 0
    if( value === 0 ) return true

    return value!=null &&
      value!='' &&
      ( !Array.isArray( value ) || value.length > 0 ) &&
      ( !isObject( value ) || !isEmpty( value ) )
  })
}

/**
 * Parse a value to bool
 *
 * @param  mixed value
 * @return bool
 */
export function parseBool( value )
{
  return ( value === true || value == 'true' )
}

/**
 * Parse a value to int or 'create'
 *
 * @param  mixed value
 * @return int|string
 */
export function parseIntOrCreate( value )
{
  return value == 'create' ? value : parseInt( value )
}

