export default [
  {
    path : '/forbidden',
    name : '403',
    component : () => import( /* webpackChunkName: "e403" */ '../../views/errors/403.vue' ),
    meta : {
      guest: true,
    },
  }
]
