import { parseBool } from '../../hooks/helpers.js'

let open = localStorage.getItem( 'drawer_open' )
open = open!=null ? parseBool( open ) : true

let mini = localStorage.getItem( 'drawer_mini' )
mini = mini!=null ? parseBool( mini ) : false

export default {
  state : {
    open: open,
    mini: mini,
  },
  getters : {},
  actions : {
    setMini( { commit }, value ){
     commit( 'SET_MINI', value )
    },
    toggleOpen( { commit } ){
      commit( 'TOGGLE_OPEN' )
    },
    toggleMini( { commit } ){
     commit( 'TOGGLE_MINI' )
    },
  },
  mutations : {
    SET_MINI( state, value ){
      state.mini = value
      localStorage.setItem( 'drawer_mini', state.mini )
    },
    TOGGLE_OPEN( state ){
      state.open = !state.open
      localStorage.setItem( 'drawer_open', state.open )
    },
    TOGGLE_MINI( state ){
      state.mini = !state.mini
      localStorage.setItem( 'drawer_mini', state.mini )
    },
  },
}
