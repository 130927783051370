import { store } from '../../store/index.js'

export default [
  {
    path: '/spare-parts',
    name: 'spareParts',
    component: () =>
      import(/* webpackChunkName: "spareParts" */ '../../views/SpareParts.vue'),
    meta: {
      breadcrumb: {
        label: 'Lista ricambi',
      },
      auth() {
        return store.getters['auth/userCan']('spare_parts_manage')
      },
    },
    children: [
      {
        name: 'sparePartCreate',
        path: 'create',
        meta: {
          inject: {
            id: 'create',
          },
          breadcrumb: {
            label: 'Nuovo ricambio',
          },
        },
      },
      {
        name: 'sparePartShow',
        path: ':id',
        meta: {
          casts: {
            id: parseInt,
          },
          breadcrumb: {
            label: 'Dettaglio ricambio',

            dynamicPath: true,
            path: (route) => `/spare-parts/${route.params.id}`,
          },
        },
        children: [
          {
            name: 'sparePartEdit',
            path: 'edit',
            meta: {
              inject: {
                action: 'edit',
              },
              breadcrumb: {
                label: 'Modifica ricambio',
              },
            },
          },
          {
            name: 'sparePartOrder',
            path: 'order',
            meta: {
              inject: {
                action: 'order',
              },
              breadcrumb: {
                label: 'Ordina ricambio',
              },
            },
          },
          {
            name: 'sparePartDestroy',
            path: 'destroy',
            meta: {
              inject: {
                action: 'destroy',
              },
            },
          },
        ],
      },
    ],
  },
]
