export default [
  {
    path : '/login',
    name : 'login',
    component : () => import( /* webpackChunkName: "login" */ '../../views/Login.vue' ),
    meta : {
      guestOnly: true,
    },
  },
  {
    path : '/forgot-password',
    name : 'forgotPassword',
    component : () => import( /* webpackChunkName: "forgotPassword" */ '../../views/ForgotPassword.vue' ),
    meta : {
      guestOnly: true,
    },
  },
  {
    path : '/reset-password',
    name : 'resetPassword',
    component : () => import( /* webpackChunkName: "resetPassword" */ '../../views/ResetPassword.vue' ),
    meta : {
      guestOnly: true,
    },
  },
  {
    path: '/profile/:tab?',
    name: 'profile',
    component: () => import( /* webpackChunkName: "profile" */ '../../views/Profile.vue' ),
  }
]
