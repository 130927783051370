import Api from './api.js'

export default function useCrud( END_POINT )
{

  return {

    destroy( id ){
      return Api.delete( `${END_POINT}/${id}` )
    },

    index( params = {} ){
      return Api.get( END_POINT, { params } )
    },

    show( id ){
      return Api.get( `${END_POINT}/${id}` )
    },

    store( data ){
      return Api.post( END_POINT, data )
    },

    update( id, data ){
      return Api.patch( `${END_POINT}/${id}`, data )
    },

  }

}
