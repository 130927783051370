<template>
  <q-page
    :class="{ 'q-pt-none' : toolbar }"
    v-bind="$attrs"
  >

    <!-- page content -->
    <div class="wrapper full-width" :style="{ paddingTop: `${headerHeight}px` }">
      <slot/>
    </div>
    <!-- page content -->

    <q-page-sticky
      ref="header"
      expand
      position="top">

      <div class="full-width">
        <q-toolbar
          class="bg-white wrapped q-px-lg q-pt-sm q-pb-none"
          style="width:auto;z-index:2"
          :class="{ 'shadow-4': !breadcrumb.length }"
          v-if="toolbar"
        >
          <q-toolbar-title class="q-mb-lg">
            <!-- title slot -->
            <slot name="title">
              <h1
                class="text-h4 text-weight-bolder q-pa-none q-mb-none"
                v-if="title"
              >
                {{ title }}
              </h1>
            </slot>

            <!-- subtitle slot -->
            <slot name="subtitle">
              <p
                class="text-subtitle2 text-grey-1 q-mb-none"
                v-if="subtitle"
              >
                {{ subtitle }}
              </p>
            </slot>
          </q-toolbar-title>

          <!-- action slot -->
          <slot name="actions" />

        </q-toolbar>

        <!-- breadcrumb -->
        <q-toolbar
          class="bg-grey-2 text-grey-8 wrapped q-px-lg shadow-4"
          style="width:auto;z-index:1"
          v-if="breadcrumb.length"
        >
          <BreadCrumb
            active-color="pink"
            separator-color="grey-5"
            :breadcrumb="breadcrumb">

            <template v-slot:separator>
              <q-icon
                size="1.5em"
                name="chevron_right"
                color="grey-5"
              />
            </template>

          </BreadCrumb>
        </q-toolbar>
      </div>
    </q-page-sticky>

  </q-page>
</template>

<script>
import BreadCrumb from '../BreadCrumb.vue'
import useWindowEvent from '../../hooks/windowEvent.js'
import { dom } from 'quasar'
import { ref, computed } from '@vue/composition-api'

export default{
  name: 'BasePage',
  inheritAttrs: false,

  components: {
    BreadCrumb,
  },

  props: {
    title: {
      type: String,
    },

    subtitle: {
      type: String,
    },

    breadcrumb: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { slots, refs }){
    const { height } = dom

    const toolbar = computed(() => {
      return props.title || props.subtitle || slots.title || slots.subtitle || slots.actions
    })

    const headerHeight = ref( 0 )

    useWindowEvent( 'resize', () => {
      headerHeight.value = height( refs.header.$el )
    }, { immediate: true })

    return {
      headerHeight,
      toolbar
    }
  }
}
</script>
