const menuItems = [
  {
    icon: 'view-dashboard-outline',
    label: 'Dashboard',
    route: 'home',
  },
  {
    icon: 'tools',
    label: 'Manutenzioni',
    route: 'maintenances',
  },
  {
    icon: 'account-supervisor-outline',
    label: 'Clienti',
    route: 'customers',
  },
  {
    icon: 'rv-truck',
    label: 'Veicoli',
    route: 'vehicles',
  },
  {
    icon: 'bolt',
    label: 'Magazzino ricambi',
    route: 'spareParts',
    permission: 'spare_parts_manage',
  },
  {
    icon: 'comment-question-outline',
    label: 'Richieste ricambi',
    route: 'sparePartsRequests',
    permission: 'spare_parts_manage',
  },
  {
    icon: 'timeline-clock-outline',
    label: 'Schede attive',
    route: 'pending-jobs',
  },
  {
    icon: 'chart-areaspline',
    label: 'Report',
    route: 'reports',
    permission: 'report_view',
  },
  {
    icon: 'account-supervisor-circle',
    label: 'Gestione utenti',
    route: 'users',
    permission: 'user_manage',
  },
  {
    icon: 'email-multiple-outline',
    label: 'Gestione Modelli Email',
    route: 'emailMessages',
    permission: 'email_message_manage',
  },
]

export { menuItems }
