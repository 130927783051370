<template>
  <q-dialog
    v-bind="{ persistent: true, ...$attrs}"
    v-on="$listeners"
  >
    <q-card :style="style">

      <q-card-section class="flex q-pb-none">
        <slot name="header">
          <div class="text-h6">{{ title }}</div>
        </slot>
        <q-space />
        <q-btn v-if="closeBtn" icon="mdi-close" flat round dense v-close-popup />
      </q-card-section>

      <q-card-section style="max-height: 70vh" class="scroll">
        <slot>
          {{ message }}
        </slot>
      </q-card-section>

      <q-card-actions align="right" v-if="!noActions">

        <slot name="actions">

          <template v-if="confirm">

            <q-btn
              flat
              class="q-ml-md q-px-sm"
              :label="koLabel"
              :color="koColor"
              v-bind="!!koIcon ? { icon:koIcon } : {}"
              @click="$emit('close')"
            />

            <q-btn
              flat
              class="q-ml-md q-px-sm"
              :label="label"
              :color="color"
              v-bind="!!icon ? { icon } : {}"
              @click="$emit('confirm')"
            />

          </template>

          <template v-else>

            <q-btn
              flat
              class="q-ml-md q-px-sm"
              :label="label"
              :color="color"
              v-bind="!!icon ? { icon } : {}"
              @click="$emit('confirm')"
            />

          </template>

        </slot>

        <slot name="extra-buttons" />

      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'BaseModal',
  inheritAttrs: false,

  props : {
    closeBtn: Boolean,
    color: {
      type: String,
      default: 'primary',
    },
    confirm: Boolean,
    koColor: {
      type: String,
      default: 'dark',
    },
    koIcon: {
      type: String,
      default: null,
    },
    koLabel: {
      type: String,
      default: 'Annulla',
    },
    message: String,
    noActions: Boolean,
    label: {
      type: String,
      default: 'Conferma',
    },
    icon: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: '',
    },
    title: String,
  },

  computed : {
    style(){
      let style = { maxWidth: '80vw' }

      switch( this.size ){
        case 'sm':
          style.width = '300px'
          break;
        case 'md':
          style.width = '480px'
          break;
        case 'lg':
          style.width = '700px'
          break;
        default:
          if( this.size ){
            style.width = this.size
          }
          break;
      }

      return style
    }
  }

}
</script>
