import Api from '../../apis/users.js'

export default {
  state : {
    collection: [],
    loaded: false,

  },

  getters : {
    filter : state => query => {
      if( !state.loaded ) return []
      if( !query.length ) return state.collection
      return state.collection.filter( item =>item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0 )
    },

    getUserById : state => ( id ) => {
      if( !state.loaded ) return {}
      return state.collection.find( item => {
        return item.id == id
      })
    },

  },
  actions : {
    load( { commit, state } ){
      if( !state.loaded ){
        return Api.index().then( response => {
          commit( 'SET_COLLECTION', response.data )
        })
      }
    },

  },
  mutations : {
    SET_COLLECTION( state, data ){
      state.collection = data
      state.loaded = true
    },

  },
}
