<template>
  <q-layout view="lHh Lpr lff">

    <template v-if="isAuthenticated">
      <Header />
      <Drawer />
    </template>

    <q-page-container>
      <router-view />
    </q-page-container>

  </q-layout>
</template>

<script>
import Drawer from '../components/Drawer.vue'
import Header from '../components/Header.vue'
import useAuth from '../hooks/auth.js'
import { LoadingBar } from 'quasar'
import { useActions, useState } from '../hooks/store.js'
import { watch, onMounted } from '@vue/composition-api'

export default {
  name: 'App',
  components: {
    Drawer,
    Header,
  },
  setup(){
    const { isAuthenticated } = useAuth()
    const { ajaxLoading } = useState([ 'ajaxLoading' ])
    const { setUuid } = useActions([ 'setUuid' ])

    //set uuid
    onMounted( () => setUuid() )

    //loading bar
    watch( () => ajaxLoading.value, loading => {
      if( loading ){
        LoadingBar.start()
      }
      else {
        LoadingBar.stop()
      }
    }, { immediate: true })

    return {
      isAuthenticated,
    }
  }
}
</script>
