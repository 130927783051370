import Api from '../../apis/auth.js'
import BaseApi from '../../apis/api.js'

export default {
  state: {
    user: {},
    token: localStorage.getItem('token'),
  },
  getters: {
    isAuthenticated: (state) => {
      return !!state.token
    },

    userCan: (state) => (action) => {
      return (
        state.user.root ||
        (state.user.permissions !== undefined &&
          state.user.permissions.includes(action))
      )
    },
  },
  actions: {
    loadUserData({ commit }) {
      return Api.user().then((user) => {
        commit('SET_USER', user.data)
        return user
      })
    },
    tokenExpired({ commit }) {
      commit('RESET_TOKEN')
      commit('SET_USER', {})
      return Api.logout()
    },
    resetToken({ commit }) {
      commit('RESET_TOKEN')
    },
    setToken({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    setUserData({ commit }, data) {
      commit('SET_USER', data)
    },
  },
  mutations: {
    RESET_TOKEN(state) {
      state.token = null
      state.user = {}
      localStorage.removeItem('token')
      delete BaseApi.defaults.headers.common['Authorization']
    },
    SET_TOKEN(state, token) {
      state.token = token
      localStorage.setItem('token', token)
      BaseApi.defaults.headers.common['Authorization'] = `Bearer ${token}`
    },
    SET_USER(state, data) {
      state.user = data
    },
  },
}
