import Api from "../../apis/maintenances.js";

export default {
  state: {
    status: [],
    statusLoaded: false,
    kinds: [
      { value: 0, label: "Officina" },
      { value: 1, label: "Nuovo" },
      { value: 2, label: "Usato" },
      { value: 3, label: "Noleggio" },
      { value: 4, label: "Expo" },
    ],
  },

  getters: {
    getStatusById: (state) => (id) => {
      if (!state.statusLoaded) return {};
      return state.status.find((item) => {
        return item.id == id;
      });
    },

    getKind: (state) => (value) => {
      return state.kinds.find((kind) => {
        return kind.value === value;
      });
    },
  },

  actions: {
    loadStatus({ commit, state }) {
      if (!state.statusLoaded) {
        return Api.status().then((response) => {
          commit("SET_STATUS", response.data);
        });
      }
    },
  },

  mutations: {
    SET_STATUS(state, data) {
      state.status = data;
      state.statusLoaded = true;
    },
  },
};
