<template>
  <q-header class="bg-primary text-white" reveal>
    <q-toolbar class="q-py-md">
      <q-btn
        dense
        flat
        round
        icon="mdi-menu"
        :class="{ 'q-ml-sm': open }"
        @click="toggleOpen"
      />

      <transition name="from-left">
        <Logo
          class="q-ml-sm"
          style="position: relative; top: 10px; height: 48px; margin-top: -20px"
          v-if="!open"
        />
      </transition>

      <q-btn
        class="q-ml-auto"
        dense
        flat
        round
        icon="mdi-logout"
        @click="logout"
      >
        <q-tooltip>Disconnetti</q-tooltip>
      </q-btn>
    </q-toolbar>
  </q-header>
</template>

<script>
import Logo from '../components/Logo.vue'
import useAuth from '../hooks/auth.js'
import { useActions, useState } from '../hooks/store.js'

export default {
  components: {
    Logo,
  },
  setup() {
    const { logout } = useAuth()
    const { open } = useState('drawer', ['open'])
    const { toggleOpen } = useActions('drawer', ['toggleOpen'])

    return {
      logout,
      open,
      toggleOpen,
    }
  },
}
</script>
