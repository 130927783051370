export default [
  {
    path : '/pending-jobs',
    name : 'pending-jobs',
    component : () => import( /* webpackChunkName: "pending-jobs" */ '../../views/PendingJobs.vue' ),
    meta: {
      breadcrumb: {
        label : 'Manutenzioni nel periodo selezionato',
      },
    },
  },
]
