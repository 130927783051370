import { store } from '../../store/index.js'

export default [
  {
    path: '/reports',
    name: 'reports',
    component: () =>
      import(/* webpackChunkName: "reports" */ '../../views/Reports.vue'),
    meta: {
      breadcrumb: {
        label: 'Report durata operazioni',
      },
      auth() {
        return store.getters['auth/userCan']('report_view')
      },
    },
  },
]
