import Api from '../apis/auth.js'
import { router } from  '../config/router.js'
import { computed } from '@vue/composition-api'
import { store } from '../store/index.js'

export default function useAuth()
{
  /**
   * Login into api
   *
   * @param String email
   * @param String password
   * @return Promise { response }
   */
  function login( email, password ){
    return Api.login( email, password, true ).then( response => {
      store.dispatch( 'auth/setToken', response.token )
      store.dispatch( 'auth/loadUserData' )
      return response
    })
  }

  /**
   * Logout from api
   *
   * @return Promise {void}
   */
  function logout(){
    return Api.logout().then( () => {
      store.dispatch( 'auth/resetToken' )
      store.dispatch( 'auth/setUserData', {} )
      router.push( { name : 'login' } )
    })
  }

  /**
   * Forgot password
   *
   * @return Promise {void}
   */
  function forgotPassword(){
    return router.push( { name : 'forgotPassword' } )
  }

  /**
   * Send mail recovery password
   *
   * @return Promise {void}
   */
  function passwordRecovery( email ){
    return Api.passwordRecovery( email )
  }

  /**
   * Password reset
   *
   * @return Promise {void}
   */
  function passwordReset( email, token, password, password_confirmation ){
    return Api.passwordReset( email, token, password, password_confirmation )
  }

  /**
   * Password update
   *
   * @return Promise {void}
   */
  function passwordUpdate( current_password, password, password_confirmation ){
    return Api.passwordUpdate( current_password, password, password_confirmation )
  }

  /**
   * Logged user data
   *
   * @return Object User
   */
  const user = computed( () => {
    return store.state.auth.user
  })

  /**
   * User capitalized first letter
   *
   * @return string Letter
   */
  const userInitial = computed( () => {
      return user.value.name ? user.value.name[ 0 ].toUpperCase() : ''
  })

  /**
   * Token active
   *
   * @return String Token
   */
  const token = computed( () => {
    return store.state.auth.token
  })

  /**
   * User is logged
   *
   * @return bool Logged
   */
  const isAuthenticated = computed( () => {
    return store.getters[ 'auth/isAuthenticated' ]
  })

  return {
    isAuthenticated,
    forgotPassword,
    login,
    logout,
    token,
    passwordRecovery,
    passwordReset,
    passwordUpdate,
    user,
    userInitial,
  }
}
