import { render, staticRenderFns } from "./BaseSelect.vue?vue&type=template&id=1632ec74&"
import script from "./BaseSelect.vue?vue&type=script&lang=js&"
export * from "./BaseSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect});
