import Api from './api.js'
import useCrud from './crud.js'

const END_POINT = 'operations'

export default {
  ...useCrud( END_POINT ),

  typeStore( data ){
      return Api.post( `${END_POINT}/types`, data )
  },

  typeIndex( params = {} ){
    return Api.get( `${END_POINT}/types`, { params } )
  },
}
