import { store } from '../../store/index.js'

export default [
  {
    path: '/spare-parts-request',
    name: 'sparePartsRequests',
    component: () =>
      import(
        /* webpackChunkName: "SparePartsRequests" */ '../../views/SparePartsRequests.vue'
      ),
    meta: {
      breadcrumb: {
        label: 'Richieste ricambi',
      },
      auth() {
        return store.getters['auth/userCan']('spare_parts_manage')
      },
    },
    children: [
      {
        name: 'sparePartRequestShow',
        path: ':id',
        meta: {
          casts: {
            id: parseInt,
          },
          breadcrumb: {
            label: 'Dettaglio richiesta',

            dynamicPath: true,
            path: (route) => `/spare-parts-request/${route.params.id}`,
          },
        },
        children: [
          {
            name: 'sparePartRequestDestroy',
            path: 'destroy',
            meta: {
              inject: {
                action: 'destroy',
              },
            },
          },
        ],
      },
    ],
  },
]
